<template>
  <o-table
    :items="queclinkTrackers"
    :loading="loading"
    defaultSort="number"
    :draggable="false"
    paginated
    :per-page="100"
  >
    <b-table-column v-slot="{ index }" cell-class="has-text-grey">
      {{ index + 1 }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="number" label="Numéro" sortable>
      {{ row.number }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="imei" label="IMEI" sortable>
      {{ row.imei }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="availability" label="Status" sortable>
      <b-tag :type="tags[row.availability].type">
        {{ tags[row.availability].label }}
      </b-tag>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="owner" label="Propriétaire" sortable>
      {{ capitalize(row.owner) }}
    </b-table-column>
    <b-table-column v-slot="{ row }" label="">
      <div class="buttons are-small is-right">
        <b-button
          type="is-primary"
          tag="router-link"
          :to="{ name: 'queclinkTracker.edit', params: { id: row.id } }"
        >
          <b-icon icon="edit" />
        </b-button>
      </div>
    </b-table-column>
  </o-table>
</template>

<script>
import { capitalize } from '@helpers/capitalize'
import OTable from '@components/Table.vue'
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'ListQueclinkTrackersTable',

  components: {
    OTable,
  },

  props: {
    queclinkTrackers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  created() {
    this.tags = TAGS
  },

  methods: {
    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
