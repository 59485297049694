<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li>
        <router-link :to="{ name: 'queclinkTracker.list' }"> Balises QUECLINK </router-link>
      </li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="columns">
      <div class="column is-full-mobile is-6 ml-auto">
        <queclink-tracker-search />
      </div>
    </div>
    <div v-if="queclinkTracker">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Informations</h2>
              <div class="my-auto mx-3">
                <b-tag :type="tags[queclinkTracker.availability].type">
                  {{ tags[queclinkTracker.availability].label }}
                </b-tag>
              </div>
            </div>
            <div class="card-content">
              <table class="table is-striped is-fullwidth">
                <tr>
                  <th>Numéro</th>
                  <td>{{ queclinkTracker.number }}</td>
                </tr>
                <tr>
                  <th>IMEI</th>
                  <td>{{ queclinkTracker.imei }}</td>
                </tr>
                <tr>
                  <th>Date d'achat</th>
                  <td>{{ formatDate(queclinkTracker.purchasedAt) }}</td>
                </tr>
                <tr>
                  <th>Locations depuis l'achat</th>
                  <td>{{ lives.length }}</td>
                </tr>
                <tr>
                  <th>Location sur l'année glissante</th>
                  <td>{{ totalLivesSinceLastYear }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="tile is-parent">
          <div class="tile is-child card">
            <queclink-tracker-map :id="id" class="card" />
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Changement d'état</h2>
            </div>
            <div class="card-content">
              <queclink-tracker-edit-form :queclink-tracker.sync="queclinkTracker" />
            </div>
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <div class="tile is-child card">
            <div class="card-header">
              <h2 class="card-header-title">Lives</h2>
            </div>
            <div class="card-content">
              <queclink-tracker-live-table :lives="formattedLives" :loading="loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import { format, isWithinInterval, subYears } from 'date-fns'
import PageLayout from '@components/PageLayout.vue'
import QueclinkTrackerMap from './QueclinkTrackerMap.vue'
import QueclinkTrackerSearch from './QueclinkTrackerSearch.vue'
import QueclinkTrackerEditForm from './QueclinkTrackerEditForm.vue'
import QueclinkTrackerLiveTable from './QueclinkTrackerLiveTable.vue'
import { TAGS } from '@constants/tracker/tag'

export default {
  name: 'EditQueclinkTrackerPage',

  components: {
    PageLayout,
    QueclinkTrackerMap,
    QueclinkTrackerSearch,
    QueclinkTrackerEditForm,
    QueclinkTrackerLiveTable,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      queclinkTracker: null,
      lives: [],
      liveVehicles: [],
    }
  },

  created() {
    this.tags = TAGS
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    title() {
      return this.queclinkTracker ? `QUECLINK ${this.queclinkTracker.number}` : '...'
    },

    totalLivesSinceLastYear() {
      return this.lives.filter(
        (live) =>
          isWithinInterval(live.startedAt, {
            start: subYears(new Date(), 1),
            end: new Date(),
          }) ||
          isWithinInterval(live.endedAt, {
            start: subYears(new Date(), 1),
            end: new Date(),
          }) ||
          isWithinInterval(new Date(), {
            start: live.startedAt,
            end: live.endedAt,
          }),
      ).length
    },

    formattedLives() {
      return this.lives.map((live) => ({
        ...live,
        vehicleId: this.liveVehicles[live.id]?.id,
        vehicleNumber: this.liveVehicles[live.id]?.number,
      }))
    },
  },

  methods: {
    async load() {
      this.loading = true

      this.queclinkTracker = await this.$services.queclinkTrackerService.getById(this.id)
      this.lives = await this.$services.liveService.getAllByQueclinkTrackerId(this.id)
      this.liveVehicles = await this.fetchLiveVehicles()

      this.loading = false
    },

    async fetchLiveVehicles() {
      return (await this.$services.liveVehicleService.getAllByQueclinkTrackerId(this.id)).reduce(
        (vehicles, vehicle) => ({
          ...vehicles,
          [vehicle.liveId]: { ...vehicle },
        }),
        {},
      )
    },

    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
