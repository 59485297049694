<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li>
        <router-link :to="{ name: 'queclinkTracker.list' }">Balises QUECLINK</router-link>
      </li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="box">
      <add-queclink-tracker-form />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import AddQueclinkTrackerForm from './AddQueclinkTrackerForm.vue'

export default {
  name: 'AddQueclinkTrackerPage',

  components: {
    PageLayout,
    AddQueclinkTrackerForm,
  },

  data() {
    return {
      title: 'Ajouter une balise QUECLINK',
    }
  },
}
</script>
