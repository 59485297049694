<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field
      label="Numéro*"
      :message="fields.number.error"
      :type="fields.number.error !== null ? 'is-danger' : ''"
    >
      <b-input
        v-model="fields.number.value"
        type="text"
        :icon-right="fields.number.value ? 'close-circle' : ''"
        :icon-right-clickable="!!fields.number.value"
        @icon-right-click="fields.number.value = null"
      />
    </b-field>
    <b-field
      label="IMEI*"
      :message="fields.imei.error"
      :type="fields.imei.error !== null ? 'is-danger' : ''"
    >
      <b-input
        v-model="fields.imei.value"
        type="text"
        :icon-right="fields.imei.value ? 'close-circle' : ''"
        :icon-right-clickable="!!fields.imei.value"
        @icon-right-click="fields.imei.value = null"
      />
    </b-field>
    <b-field
      label="Date d'achat*"
      :message="fields.purchasedAt.error"
      :type="fields.purchasedAt.error !== null ? 'is-danger' : ''"
    >
      <b-datepicker
        v-model="fields.purchasedAt.value"
        placeholder="Type or select a date..."
        icon="calendar-day"
        :date-formatter="dateFormat"
      />
    </b-field>
    <b-field
      label="Propriétaire*"
      :message="fields.owner.error"
      :type="fields.owner.error !== null ? 'is-danger' : ''"
    >
      <b-select v-model="fields.owner.value" label="Choisir un propriétaire" icon="user" expanded>
        <option v-for="owner in owners" :key="owner" :value="owner">
          {{ capitalize(owner) }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Commentaire"
      :message="fields.comment.error"
      :type="fields.comment.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.comment.value" type="textarea" />
    </b-field>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Ajouter </b-button>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'
import { capitalize } from '@helpers/capitalize'
import { OWNERS } from '@constants/tracker/owner.js'

export default {
  name: 'AddSpotTrackerForm',

  data() {
    return {
      loading: false,
      fields: {
        number: {
          value: '',
          error: null,
        },
        imei: {
          value: '',
          error: null,
        },
        purchasedAt: {
          value: null,
          error: null,
        },
        owner: {
          value: '',
          error: null,
        },
        comment: {
          value: '',
          error: null,
        },
      },
    }
  },

  created() {
    this.owners = OWNERS
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        const queclinkTracker = await this.$services.queclinkTrackerService.create({
          number: this.fields.number.value,
          imei: this.fields.imei.value,
          purchasedAt: this.fields.purchasedAt.value,
          comment: this.fields.comment.value,
          owner: this.fields.owner.value,
        })

        this.addToastMessage({
          text: `Le tracker Queclink ${queclinkTracker.number} a été ajouté.`,
          type: 'is-success',
        })

        this.$router.push({
          name: 'queclinkTracker.edit',
          params: { id: queclinkTracker.id },
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
        console.error(err)
      }

      this.loading = false
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },

    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
