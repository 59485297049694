<template>
  <o-upload
    v-model="csvFile"
    :loading="loading"
    :label="label"
    :type="fileType"
    @submit="submit"
    @deleteFile="csvFile = null"
  />
</template>

<script>
import OUpload from '@components/Upload.vue'
import { importCsv } from '@helpers/importCsv'
import { getErrorWrongColumn } from '@helpers/importErrorMessages'

export default {
  name: 'ImportCsv',

  components: {
    OUpload,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    format: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      csvFile: null,
      fileType: 'text/csv, application/vnd.ms-excel',
    }
  },

  methods: {
    submit() {
      const reader = new FileReader()
      reader.onload = () => {
        const data = importCsv(reader.result)
        const columns = data.map((e) => Object.keys(e))[0]
        const expectedColumns = Object.values(this.format)

        this.$emit('submit', {
          result: this.formatData(data),
          error: getErrorWrongColumn(columns, expectedColumns),
        })
      }
      reader.readAsText(this.csvFile)

      this.csvFile = null
    },

    formatData(data) {
      const keys = Object.keys(this.format)

      return data.map((value) => {
        let item = {}

        keys.forEach((key) => {
          item[key] = value[this.format[key]]
        })

        return item
      })
    },
  },
}
</script>
