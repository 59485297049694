<template>
  <b-field label="Accéder à une balise QUECLINK">
    <b-autocomplete
      :data="queclinkTrackers"
      placeholder="Numéro ou IMEI"
      :loading="loading"
      @typing="getQueclinkTrackers"
      @select="redirect"
    >
      <template v-slot="props">
        <div class="media">
          <div class="media-content">
            Balise QUECLINK #{{ props.option.number }}
            <br />
            <small>{{ `IMEI : ${props.option.imei}` }}</small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: 'QueclinkTrackerSearch',

  data() {
    return {
      loading: false,
      timeoutId: null,
      queclinkTrackers: [],
    }
  },

  methods: {
    getQueclinkTrackers(input) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }

      const keyword = input?.trim().toUpperCase()
      if (keyword.length < 2) {
        this.queclinkTrackers = []
        return
      }

      this.timeoutId = setTimeout(async () => {
        await this.fetch(keyword)
      }, 500)
    },

    async fetch(keyword) {
      this.loading = true

      try {
        this.queclinkTrackers = await this.$services.queclinkTrackerService.getAll({ keyword })
      } catch (err) {
        this.queclinkTrackers = []
        console.error(err)
      }

      this.loading = false
    },

    async redirect(queclinkTracker) {
      if (queclinkTracker.id === this.$route.params.id) {
        return
      }

      await this.$router.push({ name: 'queclinkTracker.edit', params: { id: queclinkTracker.id } })
    },
  },
}
</script>
