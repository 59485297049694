<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-notification type="is-danger" :active="error !== null" :closable="false">
      {{ error }}
    </b-notification>
    <b-field group-multiline grouped class="columns">
      <b-field
        label="Status"
        :message="fields.availability.error"
        :type="fields.availability.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input v-if="isUnavailable" disabled :value="unavailable" />
        <b-select
          v-else
          v-model="fields.availability.value"
          placeholder="Choisir un status"
          icon="history"
          expanded
        >
          <option
            v-for="availability in Object.keys(availabilities)"
            :key="availability"
            :value="availability"
          >
            {{ availabilities[availability] }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Propriétaire"
        :message="fields.owner.error"
        :type="fields.owner.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select v-model="fields.owner.value" label="Choisir un propriétaire" icon="user" expanded>
          <option v-for="owner in owners" :key="owner" :value="owner">
            {{ capitalize(owner) }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field
      label="Commentaire"
      :message="fields.comment.error"
      :type="fields.comment.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.comment.value" type="textarea" />
    </b-field>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
    </b-field>
  </form>
</template>

<script>
import { format } from 'date-fns'
import { mapActions } from 'vuex'
import { capitalize } from '@helpers/capitalize'
import { AVAILABILITIES, AVAILABILITY_UNAVAILABLE } from '@constants/tracker/availability.js'
import { OWNERS } from '@constants/tracker/owner.js'

export default {
  name: 'QueclinkTrackerEditForm',

  props: {
    queclinkTracker: {
      type: Object,
      required: true,
      validator: (v) =>
        ['id', 'owner', 'comment', 'purchasedAt', 'availability'].every((key) => key in v),
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      fields: {
        availability: {
          value: null,
          error: null,
        },
        comment: {
          value: null,
          error: null,
        },
        owner: {
          value: null,
          error: null,
        },
      },
    }
  },

  created() {
    this.owners = OWNERS
    this.unavailable = AVAILABILITIES[AVAILABILITY_UNAVAILABLE]
    this.availabilities = Object.fromEntries(
      Object.entries(AVAILABILITIES).filter(([key]) => key !== AVAILABILITY_UNAVAILABLE),
    )
  },

  watch: {
    queclinkTracker: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    isUnavailable() {
      return this.queclinkTracker.availability === AVAILABILITY_UNAVAILABLE
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.availability.value = this.queclinkTracker.availability
      this.fields.comment.value = this.queclinkTracker.comment
      this.fields.owner.value = this.queclinkTracker.owner
    },

    async submit() {
      this.error = null
      this.loading = true

      try {
        const queclinkTracker = await this.$services.queclinkTrackerService.update(
          this.queclinkTracker.id,
          {
            availability:
              this.fields.availability.value !== AVAILABILITY_UNAVAILABLE
                ? this.fields.availability.value
                : null,
            comment: this.fields.comment.value,
            owner: this.fields.owner.value,
          },
        )
        this.$emit('update:queclinkTracker', queclinkTracker)
        this.addToastMessage({
          text: 'Les modifications ont bien été enregistré.',
          type: 'is-success',
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    capitalize(value) {
      return capitalize(value)
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
